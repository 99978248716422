import Link from "next/link"
import LoggedOutLayout from "@/components/layouts/LoggedOutLayout"
import LoginForm from "@/components/pages/login/LoginForm"
import styles from "@/styles/LoggedOutLayout.module.scss"
import typo from "@/styles/Typography.module.scss"
import colors from "@/styles/Colors.module.scss"
import classNames from "classnames"
import * as amplitude from "@amplitude/analytics-browser"
import useAccount from "@/hooks/api-hooks/useAccount"
import DisabledDialog from "@/components/dialogs/DisabledDialog"

export default function IndexPage() {
  localStorage.clear()
  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API)

  const { error } = useAccount()

  return (
    <LoggedOutLayout title="Log In">
      <main className={styles.body}>
        <div className={styles.left}>
          <div className={styles.text}>
            <h1 className={classNames(typo.h2, colors.white)}>
              Welcome to NextMe 2.0 👋
            </h1>
            <p className={classNames(typo.large, colors.white)}>
              Our web version has arrived! NextMe is a modern virtual waiting room that makes it simple for businesses to manage their waitlist and serve more customers. Eliminate long lines and create amazing first impressions, all while saving money on outdated technology.
            </p>
          </div>
          <img
            src="/img/waiting.svg"
            alt="People waiting in line"
            className={styles.img}
          />
        </div>
        <div className="d-flex flex-column align-items-center">
          <LoginForm />

          <p className={styles.textUnderForm}>
            New to NextMe?{" "}
            <Link href="/signup">
              <a className={styles.link} id="signupLink">
                Sign Up for Free
              </a>
            </Link>
          </p>
        </div>
      </main>
      <DisabledDialog open={error?.response.status === 403} />
    </LoggedOutLayout>
  )
}
