import styles from "@/styles/Modal.module.css"
import buttonStyles from "@/styles/Buttons.module.scss"
import { Box, Modal } from "@mui/material"
import Link from "next/link"
import { HELP_CENTER_LINK, TOC_LINK } from "@/consts/misc"
import { AlertTriangle } from "react-feather"
import classNames from "classnames"

export default function DisabledDialog({ open }) {
  return (
    <Modal open={open}>
      <Box className={styles.modalBox} style={{ width: 500 }}>
        <div className="d-flex align-items-center mb-2">
          <AlertTriangle className="me-2" />
          <h5 className={classNames(styles["custom-modal-title"], "mb-0")}>
            Your business has been disabled
          </h5>
        </div>
        <p>
          This can happen for a number of reasons, ranging from a violation of
          our{" "}
          <a target="_blank" className="font-bold" href={TOC_LINK}>
            Terms of Service
          </a>{" "}
          to a deactivation request. If you believe this was a mistake, please
          contact us at{" "}
          <a className="font-bold" href="mailto:support@nextmeapp.com">
            support@nextmeapp.com
          </a>
        </p>
        <br />
        <Box className={styles.modalButtonRow}>
          <button type="button" className={classNames("w-100", buttonStyles.grayBtn)}>
            <Link href="/logout">
              <a>Close</a>
            </Link>
          </button>
          <button type="button" className={classNames("w-100", buttonStyles.twilightBtn)}>
            <Link href="https://intercom.help/nextme-app/en/articles/8425086-why-has-my-business-been-disabled-or-frozen" passHref>
              <a target="_blank">Learn More</a>
            </Link>
          </button>
        </Box>
      </Box>
    </Modal>
  )
}
