import axios from "axios"
import {
  ChangePasswordRequest,
  ForgotPasswordRequest,
} from "@/api/interface/login"

export const forgotPassword = (payload: ForgotPasswordRequest) =>
  axios.post(`forgot-password`, payload)

export const changePassword = (payload: ChangePasswordRequest) =>
  axios.post(`reset-password`, payload)
