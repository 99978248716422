import axios from "axios"
import { LoginRequest } from "@/api/interface/login"

// gets the CSRF token from Sanctum, needed before making any authenticated API calls
export const getCSRFToken = () => axios.get(`sanctum/csrf-cookie`)

export const postLogin = (payload: LoginRequest) => axios.post(`login`, payload)

export const postLogout = () => axios.post(`logout`)

export const postTokenLogin = (token: string | string[]) => axios.post('token-login', {}, {
    headers: {
        'Authorization': `Bearer ${token}`
    }
})
