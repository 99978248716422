import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { useRouter } from "next/router"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { getCSRFToken, postLogin } from "@/api/nextme/login"
import styles from "@/styles/RegistrationForm.module.scss"
import navbarStyles from "@/styles/Navbar.module.css"
import typo from "@/styles/Typography.module.scss"
import colors from "@/styles/Colors.module.scss"
import buttonStyles from "@/styles/Buttons.module.scss"
import { forgotPassword } from "@/api/nextme/reset-password"
import { isValidEmail } from "@/utils/validators"
import classNames from "classnames"
import { printAxiosErrors } from "@/utils/util-functions"
import { setAlert } from "@/store/slices/alertSlice"
import { useDispatch } from "react-redux"
import Link from "next/link"
import { track } from "@amplitude/analytics-browser"
import DisabledDialog from "@/components/dialogs/DisabledDialog"

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(4),
      width: "100%",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(0px, -22px) scale(0.9)",
      zIndex: 0,
      color: "#333233",
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#005BFF",
    },
    "& .MuiInputBase-root": {
      fontSize: "16px",
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    },
    "& .MuiButtonGroup-root": {
      marginTop: theme.spacing(4),
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
  },
  formControl: {
    marginTop: theme.spacing(4),
    width: "100%",
    "& .MuiInputBase-root": {
      height: "38px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))

const GreenCheckbox = withStyles({
  root: {
    color: "#BCBCBC",
    "&$checked": {
      color: "#25C955",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

// TODO: refactor this component into several smaller components
function LoginForm() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const router = useRouter()

  const [is419Error, setIs419Error] = useState(false)
  const [isAccountDisabled, setIsAccountDisabled] = useState(false)

  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [isValidLoginEmail, setIsValidLoginEmail] = useState(true)
  const [isValidLoginPass, setIsValidLoginPass] = useState(true)
  const [loginResult, setLoginResult] = useState({
    status: "waiting",
    message: "",
  })
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
    remember: false,
  })
  const [resetEmail, setResetEmail] = useState("")
  const [showResetModal, setShowResetModal] = useState(false)
  const handleClose = () => setShowResetModal(false)
  const handleShow = () => setShowResetModal(true)
  const [isValidResetPassEmail, setIsValidResetPassEmail] = useState(true)
  const [isPassResetSubmitting, setIsPassResetSubmitting] = useState(false)
  const [passResetLocked, setPassResetLocked] = useState(false)
  const [passResetRqst, setPassRqst] = useState({
    status: "waiting",
    message: "",
  })

  const processLogin = async (e) => {
    e.preventDefault()

    if (isFormSubmitting) {
      return
    }
    setIsFormSubmitting(true)
    setLoginResult({
      status: "processing",
      message: "",
    })
    track("Logged In")

    setBackdropOpen(true)

    await getCSRFToken()
      .then(async () => await postLogin(loginFormData))
      .then(async () => {
        setIsValidLoginEmail(true)
        setIsValidLoginPass(true)
        setLoginResult({
          status: "success",
          message: "Logging in...",
        })

        //* reload and let the app flow handle what happens next
        router.reload()
      })
      .catch((err) => {
        console.error(printAxiosErrors(err))

        if (err?.response?.status === 419) {
          setIs419Error(true)
        }

        if (
          err?.response?.data?.message?.includes("account has been disabled")
        ) {
          setIsAccountDisabled(true)
        } else {
          dispatch(
            setAlert({
              isOpen: true,
              title: err.response?.data?.message,
              type: "error",
              message: printAxiosErrors(err, true),
            })
          )
        }

        if (err.status >= 500) {
          setLoginResult({
            status: "error",
            message: "A system error occurred. Please try again.",
          })
        } else {
          setIsValidLoginEmail(false)
          setIsValidLoginPass(false)
          setLoginResult({
            status: "error",
            message: "The info you entered is incorrect.",
          })
        }
      })
      .finally(() => {
        setIsFormSubmitting(false)
        setBackdropOpen(false)
      })
  }

  const handlePassResetRequest = async (e) => {
    e.preventDefault()

    if (isPassResetSubmitting) {
      return
    }

    if (passResetLocked) {
      setPassRqst({
        status: "success",
        message:
          "A password request has already been sent. Please wait at least 60 seconds before requesting another.",
      })
      return
    }

    setPassRqst({
      status: "processing",
      message: "",
    })

    await forgotPassword({
      email: resetEmail,
    })
      .then((result) => {
        setPassRqst({
          status: "success",
          message:
            result.data?.message ||
            "Please check your inbox for your reset request.",
        })
        track("Requested Password Reset")
        setPassResetLocked(true)
      })
      .catch((err) => {
        console.error("Failed to send password reset request", err)

        if (err.status >= 500) {
          setPassRqst({
            status: "error",
            message: "A system error occurred. Please try again later.",
          })
        } else {
          // invalid email throttling is handled here by the API
          setPassRqst({
            status: "error",
            // message: err.response?.data?.message,
            message:
              err.response?.data?.errors?.email ||
              "We couldn't find that account. Please double-check your email address and try again.",
          })
        }
      })
      .finally(() => {
        setIsPassResetSubmitting(false)
      })
  }

  const handleShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <>
      <form
        autoComplete="off"
        onSubmit={processLogin}
        className={classNames(classes.root, styles.form)}
      >
        <h5 className={typo.h5}>Login</h5>
        <TextField
          required={true}
          error={loginResult.status === "error" || !isValidLoginEmail}
          helperText={
            loginResult.status === "error"
              ? loginResult.message
              : !isValidLoginEmail
              ? "Invalid email"
              : ""
          }
          onInput={(e) => {
            setLoginFormData({
              ...loginFormData,
              email: e.target.value,
            })
          }}
          onBlur={() => {
            setIsValidLoginEmail(isValidEmail(loginFormData.email))
          }}
          type="email"
          autoComplete="username"
          label="Email"
          value={loginFormData.email}
          variant="outlined"
          size="small"
        />

        <TextField
          required={true}
          size="small"
          label="Password"
          autoComplete="current-password"
          id="outlined-adornment-password"
          type={isPasswordVisible ? "text" : "password"}
          value={loginFormData.password}
          onChange={(e) => {
            setLoginFormData({
              ...loginFormData,
              password: e.target.value,
            })
          }}
          error={loginResult.status === "error" || !isValidLoginPass}
          helperText={
            loginResult.status === "error"
              ? loginResult.message
              : !setIsValidLoginPass
              ? "Invalid password"
              : ""
          }
          onBlur={() => {
            setIsValidLoginPass(loginFormData.password.length >= 8)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {isPasswordVisible === true ? (
                    <img src="/icons/eye-off.svg" alt="" />
                  ) : (
                    <img src="/icons/eye.svg" alt="" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <p
          className={classNames(
            colors["green"],
            typo.smallBold,
            styles.link,
            "my-2"
          )}
          onClick={handleShow}
        >
          Forgot Password
        </p>
        <FormControlLabel
          className={styles.checkbox}
          control={
            <GreenCheckbox
              checked={loginFormData.remember}
              onChange={() =>
                setLoginFormData({
                  ...loginFormData,
                  remember: !loginFormData.remember,
                })
              }
              name="checkbox"
            />
          }
          label="Remember Me"
        />
        {isFormSubmitting ? (
          <button
            disabled
            className={classNames(
              buttonStyles.greenBtn,
              typo.bold,
              "btn w-100"
            )}
          >
            Logging in...
          </button>
        ) : (
          <button
            className={classNames(
              buttonStyles.greenBtn,
              typo.bold,
              "btn w-100"
            )}
          >
            Log In
          </button>
        )}
      </form>
      <Modal
        show={showResetModal}
        onHide={handleClose}
        className={styles.modal}
        id="modal"
        centered
        dialogClassName={styles.modal}
      >
        <form
          className={classNames(styles.modalForm, classes.root)}
          method="POST"
          onSubmit={(e) => {
            e.preventDefault()
            setIsPassResetSubmitting(true)

            if (!isValidEmail(resetEmail)) {
              setIsValidResetPassEmail(false)
              return
            }

            handlePassResetRequest(e)
          }}
        >
          <div className="d-flex align-items-center mb-4">
            <img src="/icons/lock.svg" alt="" style={{ marginRight: "10px" }} />

            <h5 className={classNames(typo.h5, "mb-0")}>Reset Password</h5>
          </div>
          <p className="mb-0">
            Please enter the email associated with your NextMe account and we’ll
            send you a link to reset your password.
          </p>

          <TextField
            label="Email"
            autoComplete="username"
            value={resetEmail}
            variant="outlined"
            size="small"
            onInput={(e) => {
              setResetEmail(e.target.value)
            }}
            onBlur={() => {
              setIsValidResetPassEmail(isValidEmail(resetEmail))
            }}
            error={!isValidResetPassEmail}
            helperText={!isValidResetPassEmail ? "Invalid Email" : ""}
          />

          {passResetRqst.status === "success" && (
            <div className="text-center text-success mt-4">
              {passResetRqst.message}
            </div>
          )}

          {passResetRqst.status === "error" && (
            <div className="text-center text-danger mt-4">
              {passResetRqst.message}
            </div>
          )}

          <div className="text-center mt-6 flex gap-x-4">
            <button
              className={classNames("btn w-50", buttonStyles.grayBtn)}
              onClick={handleClose}
            >
              Close
            </button>

            <button
              className={classNames("btn w-50", buttonStyles.greenBtn)}
              type="submit"
              disabled={isPassResetSubmitting}
            >
              Send{isPassResetSubmitting ? "ing..." : ""}
            </button>
          </div>
        </form>
      </Modal>
      <div>
        <Backdrop className={classes.backdrop} open={backdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Modal show={is419Error}>
        <div style={{ padding: "1rem" }}>
          <p>
            Something has gone wrong with attempting to log in. Please click
            this button and try again.
          </p>
          <br />
          <Link href="/logout" passHref>
            <button id="login-btn" className={navbarStyles.loginBtn}>
              Fix Unknown Error
            </button>
          </Link>
        </div>
      </Modal>
      <DisabledDialog open={isAccountDisabled} />
    </>
  )
}

export default LoginForm
